export default {
  data () {
    return {
      dataMixinDepositsFields: [
        {
          label: 'Team / Project',
          key: 'team_name',
          sortable: false,
          tdClass: 'call-td call-td--bold',
          thClass: 'call-th call-tabel--span',
          thStyle: { width: '34%' }
        },
        {
          label: 'Calls',
          key: 'totals.successful_calls_count',
          sortable: false,
          tdClass: 'call-td call-tabel--center',
          thClass: 'call-th call-tabel--center call-tabel--span',
          thStyle: { width: '15%' }
        },
        {
          label: 'Tasks',
          key: 'totals.all_tasks_count',
          sortable: false,
          tdClass: 'call-td call-tabel--center',
          thClass: 'call-th call-tabel--center call-tabel--span',
          thStyle: { width: '15%' }
        },
        {
          label: 'Total Deposit',
          key: 'totals.deposits_sum_usd',
          sortable: false,
          tdClass: 'call-td call-tabel--center',
          thClass: 'call-th call-tabel--center call-tabel--span',
          thStyle: { width: '15%' }
        },
        {
          label: 'GGR',
          key: 'totals.ggr_usd',
          sortable: false,
          tdClass: 'call-td call-tabel--center',
          thClass: 'call-th call-tabel--center call-tabel--span',
          thStyle: { width: '15%' }
        },
        {
          label: '',
          key: 'actions',
          tdClass: 'call-tabel-action',
          thClass: 'call-tabel-action call-tabel--span',
          thStyle: { width: '6%' }
        }
      ]
    };
  },
  methods: {
    onHover (item) {
      item.actions = true;
    },
    onUnHover (item) {
      item.actions = false;
    }
  }
};
