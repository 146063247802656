<template>
  <div class="home">
    <div class="home-wrapper">
      <div class="home-left">
        <div class="filter-wrapper">
          <div>
            <v-select
              v-model="dataSelectLastDayValue"
              :options="dataSelectOptionsLastDay"
              :filterable="false"
              :searchable="false"
              :components="{ Deselect: dataComponentDeselect }"
              placeholder="Custom"
              :class="[
                'call-select',
                dataSelectLastDayValue ? 'call-select--value' : null
              ]"
              @input="$_ajax({ reset: true })">
              <template #open-indicator="{ attributes }">
                <span v-bind="attributes">
                  <font-awesome-icon :icon="dataIconDown" />
                </span>
              </template>
            </v-select>
            <div class="date-range-picker">
              <DatePicker
                v-model="dateRange"
                range
                type="daterange"
                format="DD MMM YYYY"
                placeholder="Start Date - End Date"
                :disabled="dataSelectLastDayValue.key !== 'custom'"
                popper-class="green-theme"
                range-separator=" — "
                input-class="custom-input"
                @change="changeDateRange"
                @clear="resetToDefault">
                <template #icon>
                  <b-icon-calendar class="calendar-icon" />
                </template>
              </DatePicker>
            </div>
            <v-select
              v-if="computedIsAdmin"
              v-model="dataSelectTeamsValue"
              :options="dataSelectOptionsTeam"
              label="name"
              :components="{ Deselect: dataComponentDeselect }"
              placeholder="All Teams"
              :class="[
                'call-select',
                dataSelectTeamsValue ? 'call-select--value' : null
              ]"
              @input="$_ajax({ reset: true })">
              <template #open-indicator="{ attributes }">
                <span v-bind="attributes">
                  <font-awesome-icon :icon="dataIconDown" />
                </span>
              </template>
              <template
                slot="option"
                slot-scope="option">
                <div class="d-center">
                  {{ option.name }}
                </div>
              </template>
            </v-select>
          </div>
        </div>
        <div class="table-wrapper">
          <b-table
            hover
            no-local-sorting
            table-class="deposits-table"
            :fields="dataMixinDepositsFields"
            :items="dataTeamsList"
            tbody-tr-class="row-white"
            @row-hovered="onHover"
            @row-unhovered="onUnHover">
            <template #cell(actions)="data">
              <call-table-deposits-actions
                v-show="data.item.actions || data.item._showDetails"
                :block="!data.item.is_active"
                :rotate="data.item._showDetails"
                @on-action="value => onActions(data, value)" />
            </template>
            <template #row-details="row">
              <!-- start sub -->

              <b-table
                hover
                no-local-sorting
                table-class="deposits-subtable"
                :fields="dataMixinDepositsProjectFields"
                :items="
                  row.item.projects.map(item => {
                    item.actions = '';
                    return item;
                  })
                "
                tbody-tr-class="row-blue"
                @row-hovered="onHover"
                @row-unhovered="onUnHover" />
              <!-- end sub -->
            </template>
          </b-table>
        </div>
        <!-- <call-modal-add-team
          v-model="dataShowAddTeam"
          @on-update="() => $_ajax()" /> -->
        <!-- <call-modal-update-team
          v-model="dataShowUpdateTeam"
          :team-id="dataShowUpdateTeamId"
          @on-update="() => $_ajax()" /> -->
      </div>
      <div class="home-right">
        <!-- 235235253
        {{ dateRange }}
        {{ dataSelectLastDayValue }}
        {{ formattedRange }} -->
      </div>
    </div>
  </div>
</template>

<script>
import { BTable } from 'bootstrap-vue';
// import CallButton from '../../../components/common/CallButton';
import {
  faCheck,
  faPlus,
  faPowerOff,
  faSearch
} from '@fortawesome/free-solid-svg-icons';
// import CallInput from '../../../components/common/CallInput';
import { mapGetters } from 'vuex';
import {
  // TEAMS_BLOCKED,
  // TEAMS_GET_ALL,
  // USERS_GET_ALL,
  // GET_SMS_SCHEMAS,
  TEAMS_GET_ALL,
  DEPOSITS_GET_ALL
} from '../../../store/types/actions.types';
// import debounce from 'lodash/debounce';
import depositsTable from '../../../mixins/table/depositsTable';
import depositsProjectSubTable from '../../../mixins/table/depositsProjectSubTable';
// import CallModalAddTeam from '../../../components/common/modals/CallAddTeam';
import selectTeamLeader from '../../../mixins/select/selectTeamLeader';
import selectDepositsPeriod from '../../../mixins/select/selectDepositsPeriod';
// import CallModalUpdateTeam from '../../../components/common/modals/CallUpdateTeam';
// import CallPagination from '../../../components/desktop/CallPagination';
import helper from '../../../service/helper';
import selectAllTeams from '../../../mixins/select/selectAllTeams';
// import { ID_ROLE_TEAM_LEADER } from '../../../service/consts';
import CallTableDepositsActions from '../../../components/desktop/CallTableDepositsActions';
import moment from 'moment';

const defaultDate = [
  moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'),
  moment().endOf('month').format('YYYY-MM-DD HH:mm:ss')
];

export default {
  name: 'PageTeams',
  components: {
    CallTableDepositsActions,
    // CallPagination,
    // CallModalUpdateTeam,
    // CallModalAddTeam,
    // CallInput,
    // BFormGroup,
    // BFormDatepicker,
    // BInputGroup,
    // BInputGroupPrepend,
    // CallButton,
    BTable
  },
  mixins: [
    depositsTable,
    selectTeamLeader,
    depositsProjectSubTable,
    selectAllTeams,
    selectDepositsPeriod
  ],
  data () {
    return {
      dataAddIcon: faPlus,
      dataIconSearch: faSearch,
      dataIconCheck: faCheck,
      dataIconLock: faPowerOff,
      dataShowAddTeam: false,
      dataShowUpdateTeam: false,
      dataShowUpdateTeamId: null,
      dataTeamsList: [],
      dataSearch: null,
      dataDesc: null,
      dataOrderBy: null,
      dataObjPagination: {},
      //
      startDate: null,
      endDate: null,
      //
      defaultDateRange: [
        new Date(defaultDate[0]),
        new Date(defaultDate[1])
      ],
      dateRange: [
        new Date(defaultDate[0]),
        new Date(defaultDate[1])
      ],
      formattedRange: [
        defaultDate[0],
        defaultDate[1]
      ]
    };
  },
  computed: {
    ...mapGetters({
      computedIsAdmin: 'auth/isAdmin'
    })
  },
  watch: {
    dataSelectTeamLeaderValue () {
      this.$_ajax({ reset: true });
    }
  },
  async created () {
    this.$_ajax = ((options = {}) => {
      // let _page = 0;
      // let _limit = 50;

      const { currentRoute: { name: pageName } = {} } = this.$router;

      const _filterKey = `_filter${pageName}`;

      if (!helper.isEmpty(this.$route.query, _filterKey)) {
        try {
          options = helper.clearObject(
            JSON.parse(this.$route.query[_filterKey])
          );

          const _arrFilter = [
            // 'search',
            'teamId',
            'period'
            // 'desc',
            // 'orderBy',
            // 'limit',
            // 'page'
          ];

          for (const _key of _arrFilter) {
            if (!helper.isEmpty(options, _key)) {
              switch (_key) {
                // case 'search':
                //   this.dataSearch = options[_key];
                //   break;
                case 'teamId':
                  this.dataSelectTeamLeaderValue = options[_key];
                  break;
                case 'period':
                  this.dataSelectLastDayValue = options[_key];
                  break;
                // case 'desc':
                //   this.dataDesc = options[_key];
                //   break;
                // case 'orderBy':
                //   this.dataOrderBy = options[_key];
                //   break;
                // case 'limit':
                //   _limit = options[_key];
                //   break;
                // case 'page':
                //   _page = options[_key];
                //   break;
              }
            }
          }
        } catch (e) {
          console.log('Error query params');
        }
      }

      return async ({
        teamId = this.dataSelectTeamsValue,
        dateRange = this.formattedRange
      } = {}) => {
        // if (!helper.isEmpty(page) && page !== _page) {
        //   _page = page;
        // }

        // if (!helper.isEmpty(limit) && limit !== _limit) {
        //   _limit = limit;
        // }

        // if (reset) {
        //   _page = 0;
        // }

        if (!this.dataSelectLastDayValue) {
          this.dataSelectLastDayValue = {
            ...this.dataSelectLastDayValueDefault
          };
        }

        const { payload } = await this.$store.dispatch(
          `deposits/${DEPOSITS_GET_ALL}`,
          {
            params: {
              start_timestamp: dateRange[0],
              end_timestamp: dateRange[1],
              timezone: -new Date().getTimezoneOffset(),
              team_ids: [teamId?.id]
            }
          }
        );

        // this.dataObjPagination = paging;
        this.dataTeamsList = payload.map(item => {
          item.actions = false;
          item._showDetails = false;
          // item.showDetails = false;
          // if ()
          return item;
        });

        this.$router
          .replace({
            ...this.$router.currentRoute,
            query: {
              [_filterKey]: JSON.stringify(
                helper.clearObject({
                  teamId,
                  period:
                    this.dataSelectLastDayValue?.key === -1
                      ? null
                      : this.dataSelectLastDayValue

                  //   search,
                  //   leadId,
                  //   desc,
                  //   orderBy,
                  // limit: _limit,
                  //   page: _page
                })
              )
            }
          })
          .catch(() => {});

        options = {};
      };
    })();

    this.$_ajax();

    const [
      {
        payload: { list: listTeam = [] }
      }
    ] = await Promise.all([
      this.$store.dispatch(`team/${TEAMS_GET_ALL}`, {
        params: {
          // role: ID_ROLE_TEAM_LEADER,
          short: true
        }
      }),
      this.$_ajax()
    ]);

    this.dataSelectOptionsTeam = listTeam;
  },
  methods: {
    resetToDefault () {
      this.dateRange = [...this.defaultDateRange];
    },
    changeDateRange () {
      setTimeout(() => {
        if (this.dateRange.length === 2) {
          this.formattedRange = [
            moment(this.dateRange[0]).format('YYYY-MM-DD HH:mm:ss'),
            moment(this.dateRange[1])
              .endOf('day')
              .format('YYYY-MM-DD HH:mm:ss')
          ];
          this.$_ajax();
        }
      }, 10);
    },

    async onActions (row, action) {
      row.toggleDetails();
      console.log(row, action);
    }
  }
};
</script>

<style lang="scss" scoped>
.home-wrapper {
  display: flex;
  > div {
    width: 50%;
    gap: 16px;
  }
}
</style>

<style lang="scss">
.deposits-table {
  .row-blue {
    background-color: #f9f9f9;
  }

  .row-white {
    background-color: #fff;
  }
  .deposits-subtable {
    thead {
      height: 1px;

      * {
        padding: 0;
      }
      // display: none;
    }
  }
}
.home-left {
  .mx-input-wrapper {
    .custom-input {
      width: 100%;
    }
  }
  .date-range-picker {
    max-width: 220px;
    display: flex;
    align-items: center;
    background: #eeeeef;
    padding-left: 8px;
    border-radius: 8px;
    margin-right: 8px;
  }

  .custom-input {
    border: none;
    background: transparent;
    font-size: 14px;
    width: 100%;
  }
}
.calendar-icon {
  margin-right: 8px;
  color: #20a052;
}

.green-theme .mx-calendar {
  background-color: #eeeeef;
  border-color: #20a052;
}

.mx-table-date .today,
.mx-btn:hover {
  color: #20a052;
}

.mx-calendar-content .cell.active {
  background-color: #20a052;
}

.mx-calendar-content .cell.in-range,
.mx-calendar-content .cell.hover-in-range {
  color: #73879c;
  background-color: #c8e6c9;
}

.green-theme .mx-calendar-header,
.green-theme .mx-calendar-content {
  background-color: #c8e6c9;
}

.green-theme .mx-calendar-selected {
  background-color: #66bb6a;
  color: white;
}
</style>
