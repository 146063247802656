<template>
  <div
    class="table_actions "
    :class="[rotate && 'table_actions-rotate']">
    <call-tooltip
      placement="top"
      :content="!rotate ? 'Show Projects' : 'Hide Projects'">
      <call-button
        outlet
        type="success"
        round
        not-border
        :icon="dataAngleUp"
        @click="clickAction('toggle')" />
    </call-tooltip>
  </div>
</template>

<script>
import CallButton from '../common/CallButton';
import { faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { mapGetters } from 'vuex';

export default {
  name: 'CallTableDepositsActions',
  components: { CallButton },
  props: {
    closed: {
      type: Boolean,
      default: false
    },
    operator: {
      type: Boolean,
      default: false
    },
    time: {
      type: Boolean,
      default: false
    },
    rotate: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      computedIsAdmin: 'auth/isAdmin',
      computedIsTeamLeader: 'auth/isModerator'
    })
  },
  data () {
    return {
      // dataViewIcon: faEye,
      // dataIconUser: faUser,
      // dataIconClock: faClock,
      // dataIconCheck: faCheck,
      dataAngleUp: faAngleUp
    };
  },
  methods: {
    clickAction (action) {
      this.$emit('on-action', action);
    }
  }
};
</script>

<style lang="scss" scoped>
  .table_actions .call-button {
    transform: rotate(180deg);
    &:focus {
      box-shadow: none !important;
    }
    &:hover {
      background-color: transparent;
      color: #20a052;
    }
  }
  .table_actions-rotate .call-button {
    transform: rotate(360deg);
    margin-left: 4px;
  }
</style>
