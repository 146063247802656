export default {
  data () {
    return {
      dataMixinDepositsProjectFields: [
        {
          label: '',
          key: 'project_name',
          sortable: false,
          tdClass: 'call-td call-td--bold',
          thClass: 'call-th call-tabel--span',
          thStyle: { width: '34%' }
        },
        {
          label: '',
          key: 'successful_calls_count',
          sortable: false,
          tdClass: 'call-td call-tabel--center',
          thClass: 'call-th call-tabel--center call-tabel--span',
          thStyle: { width: '15%' }
        },
        {
          label: '',
          key: 'all_tasks_count',
          sortable: false,
          tdClass: 'call-td call-tabel--center',
          thClass: 'call-th call-tabel--center call-tabel--span',
          thStyle: { width: '15%' }
        },
        {
          label: '',
          key: 'deposits_sum_usd',
          sortable: false,
          tdClass: 'call-td call-tabel--center',
          thClass: 'call-th call-tabel--center call-tabel--span',
          thStyle: { width: '15%' }
        },
        {
          label: '',
          key: 'ggr_usd',
          sortable: false,
          tdClass: 'call-td call-tabel--center',
          thClass: 'call-th call-tabel--center call-tabel--span',
          thStyle: { width: '15%' }
        },
        {
          label: '',
          key: 'actions',
          tdClass: 'call-tabel-action',
          thClass: 'call-tabel-action call-tabel--span',
          thStyle: { width: '6%' }
        }
      ]
    };
  },
  methods: {
    onHover (item) {
      item.actions = true;
    },
    onUnHover (item) {
      item.actions = false;
    }
  }
};
